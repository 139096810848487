import MainVue from '../../main-vue';
import Checkbox from '../../components/components.checkbox.vue';
import {
    getItemSessionStorage,
    setItemSessionStorage
} from '../../helper-functions/services';

const  numberString = ["--one","--two","--three","--four","--five","--six"];

new MainVue({
    el: '#app',
    components: {
        'v-checkbox': Checkbox
    },
    data: function() {
        return {
            intlStudent: ibJS.intlStudent,
            j1Visa: ibJS.j1Visa,
            covid19TravelMedical: ibJS.covid19TravelMedical,
            visitorOutsideUSA: ibJS.visitorOutsideUSA,
            covid19: ibJS.covid19,
            schengenVisa: ibJS.schengenVisa,
            tripInsurance: ibJS.tripInsurance,
            newImmigrant: ibJS.newImmigrant,
            visitorGC: ibJS.visitorGC,
            imgTravel: ibJS.imgTravel,
            individualMedical: ibJS.individualMedical,
            studyAbroad: ibJS.studyAbroad,
            optHealthIns: ibJS.optHealthIns,
            evacRepatriation: ibJS.evacRepatriation,
            flightAccident: ibJS.flightAccident,
            annualMultitrip: ibJS.annualMultitrip,
            adandd: ibJS.adandd,
            groupTravel: ibJS.groupTravel,
            missionaryTravel: ibJS.missionaryTravel,
            kidnapRansom: ibJS.kidnapRansom,
            expatriates: ibJS.expatriates,
            marine: ibJS.marine,
            missionaryLongTerm: ibJS.missionaryLongTerm,
            shortTermMedical: ibJS.shortTermMedical,
            shortTermDomesticMedical: ibJS.shortTermDomesticMedical,
            longTermMedical: ibJS.longTermMedical,
            life: ibJS.life,
            disability: ibJS.disability,
            groupMedical: ibJS.groupMedical,
            dental: ibJS.dental,
            visitorUSA: ibJS.visitorUSA,
            currentQuoteSelect: '',
            hasTripCal: false,
            heroUrl: ibJS.newQuoteUrl,
            isFormSubmitted: false,
            sessionTime: null,
            disableSelection: false
        }
    },
    created: function(){
        let tripCalSelected = getItemSessionStorage("hasTripCal");
        if(tripCalSelected == 'yes') {
            this.hasTripCal = true;
        }
        if(this.tripInsurance && !this.visitorOutsideUSA && !this.covid19 && !this.visitorUSA) {
            this.currentQuoteSelect = '';
        }
    },
    mounted: function() {
        var $elms = document.querySelectorAll("[add-index]");
        Array.prototype.forEach.call($elms, (el,i) => {
            if(i % 2 !== 0) {
                $(el).addClass("c-Home__section--white c-Home__section--chevron-white");
            }
        });

        var $elms2 = document.querySelectorAll("[add-index-covid]");
        Array.prototype.forEach.call($elms2, (el,i) => {
            if(i % 2 == 0) {
                $(el).addClass("c-Home__section--white c-Home__section--chevron-white");
            }
        });

        if(window.ibJS.homeLanding) {
            this.init();
        }

        if (ibJS.affiliatevalues?.picturePreference != 'STD') document.querySelector(".c-Home-primary__hero-alternate")?.classList.remove('c-Home-primary__hero-alternate');
    },
    methods: {
        init() {
            /*homeHeroSection*/
            let homeHeroSection = window.homeHeroSection;
            for(let i in homeHeroSection) {
                if(homeHeroSection[i] && i != "counter"){
                    homeHeroSection.counter+=1;
                }
            }
            
            let $homeHeroSection = document.querySelectorAll(".homeHeroSection");
            let $homeHeroSectionContainer = document.querySelectorAll(".c-Home-primary__hero-quote-neutral__item");

            if(homeHeroSection && $homeHeroSection.length && $homeHeroSectionContainer.length) {
                const className = `c-Home-primary__hero-quote-neutral__item${numberString[homeHeroSection.counter -1]}`;
                if (className !== 'c-Home-primary__hero-quote-neutral__item--six') {
                    $homeHeroSectionContainer[0].classList.remove('c-Home-primary__hero-quote-neutral__item--six');
                    $homeHeroSectionContainer[0].classList.add(className);
                }
            }

            /*primaryFeaturedHome*/
            let primaryFeaturedHome = window.primaryFeaturedHome;
            for(let i in primaryFeaturedHome) {
                if(primaryFeaturedHome[i] && i != "counter"){
                    primaryFeaturedHome.counter+=1;
                }
            }
            let $primaryFeaturedHomeSection = document.querySelectorAll(".primaryFeaturedHomeSection");

            for(let i = 0 ; i < $primaryFeaturedHomeSection.length; i++) {
                $primaryFeaturedHomeSection[i].classList.add("c-Home__card-item"+ numberString[primaryFeaturedHome.counter -1]);
            }

            /*secondaryFeaturedHome*/
            let secondaryFeaturedHome = window.secondaryFeaturedHome;
            for(let i in secondaryFeaturedHome) {
                if(secondaryFeaturedHome[i] && i != "counter"){
                    secondaryFeaturedHome.counter+=1;
                }
            }
            let $secondaryFeaturedHomeSection = document.querySelectorAll(".secondaryFeaturedHomeSection");

            for(let i = 0 ; i < $secondaryFeaturedHomeSection.length; i++) {
                $secondaryFeaturedHomeSection[i].classList.add("c-Home__card-item--sec"+ numberString[secondaryFeaturedHome.counter - 1]);
            }

            /*newImmigrantsHomeSection*/
            let newImmigrantsHome = window.newImmigrantsHome;
            for(let i in newImmigrantsHome) {
                if(newImmigrantsHome[i] && i != "counter"){
                    newImmigrantsHome.counter+=1;
                }
            }
            let $newImmigrantsHomeSection = document.querySelectorAll(".newImmigrantsHomeSection");
            for(let i = 0 ; i < $newImmigrantsHomeSection.length; i++) {
                $newImmigrantsHomeSection[i].classList.add("c-Home__block-item"+ numberString[$newImmigrantsHomeSection.length - 1]);
            }

            /*educationalHomeSection*/
            let educationalHome = window.educationalHome;
            for(let i in educationalHome) {
                if(educationalHome[i] && i != "counter"){
                    educationalHome.counter+=1;
                }
            }
            let $educationalHomeSection = document.querySelectorAll(".educationalHomeSection");
            for(let i = 0 ; i < $educationalHomeSection.length; i++) {
                $educationalHomeSection[i].classList.add("c-Home__block-item"+ numberString[educationalHome.counter - 1]);
            }

            /*travelHomeSection*/
            let travelHome = window.travelHome;
            for(let i in travelHome) {
                if(travelHome[i] && i != "counter"){
                    travelHome.counter+=1;
                }
            }
            let $travelHomeSection = document.querySelectorAll(".travelHomeSection");
            for(let i = 0 ; i < $travelHomeSection.length; i++) {
                $travelHomeSection[i].classList.add("c-Home__block-item"+ numberString[travelHome.counter - 1]);
            }

            /*intlHealthInsuranceHomeSection*/
            let healthInsuranceHome = window.healthInsuranceHome;
            for(let i in healthInsuranceHome) {
                if(healthInsuranceHome[i] && i != "counter"){
                    healthInsuranceHome.counter+=1;
                }
            }
            let $intlHealthInsuranceHomeSection = document.querySelectorAll(".intlHealthInsuranceHomeSection");
            for(let i = 0 ; i < $intlHealthInsuranceHomeSection.length; i++) {
                $intlHealthInsuranceHomeSection[i].classList.add("c-Home__block-item"+ numberString[healthInsuranceHome.counter - 1]);
            }

            /*usDomesticHomeSection*/
            let usDomesticHomeSection = window.usDomesticHomeSection;
            for(let i in usDomesticHomeSection) {
                if(usDomesticHomeSection[i] && i != "counter"){
                    usDomesticHomeSection.counter+=1;
                }
            }
            let $usDomesticHomeSection = document.querySelectorAll(".usDomesticHomeSection");
            for(let i = 0 ; i < $usDomesticHomeSection.length; i++) {
                $usDomesticHomeSection[i].classList.add("c-Home__block-item"+ numberString[usDomesticHomeSection.counter - 1]);
            }
        },
        showHomeSection: function(homeSection, returnCount){
            //Initiate counter
            var counter = 0,
                blocks = [];
            var sectionItems;
            //Primary Featured Home Section
            if(homeSection == "primaryFeaturedHomeSection") {
                //Arrays of true/false block section values
                sectionItems = this.intlStudent || this.j1Visa;
                blocks = [this.intlStudent,this.j1Visa];
            }
            //Secondary Feaured Home Section
            else if(homeSection == "secondaryFeaturedHomeSection") {
                sectionItems = this.covid19TravelMedical || this.visitorUSA || this.visitorOutsideUSA || this.tripInsurance;
                blocks = [this.covid19TravelMedical,this.visitorUSA,this.visitorOutsideUSA,this.tripInsurance];
            }
            //New Immigrants Home Section
            else if(homeSection == "newImmigrantsHomeSection") {
                sectionItems = this.newImmigrant || this.visitorGC || this.imgTravel || this.individualMedical;
                blocks = [this.newImmigrant,this.visitorGC,this.imgTravel,this.individualMedical];
            }
            //Educational Home Section
            else if(homeSection == "educationalHomeSection") {
                sectionItems = this.studyAbroad || this.optHealthIns || this.evacRepatriation;
                blocks = [this.studyAbroad,this.optHealthIns,this.evacRepatriation];
            }
            //Travel Home Section
            else if(homeSection == "travelHomeSection") {
                sectionItems = this.flightAccident || this.annualMultitrip || this.adandd || this.groupMedical || this.missionaryTravel || this.kidnapRansom;
                blocks = [this.flightAccident,this.annualMultitrip,this.adandd,this.groupMedical,this.missionaryTravel,this.kidnapRansom];
            }
            //Intl Health Insurance Home Section
            else if(homeSection == "intlHealthInsuranceHomeSection") {
                sectionItems = this.expatriates || this.marine || this.missionaryLongTerm;
                blocks = [this.expatriates,this.marine,this.missionaryLongTerm];
            }
            //US Domestic Insurance Home Section
            else if(homeSection == "usDomesticHomeSection") {
                sectionItems = this.shortTermDomesticMedical || this.disability || this.groupMedical || this.dental;
                blocks = [this.shortTermDomesticMedical,this.disability,this.groupMedical,this.dental];
            }
            if(returnCount){
                counter = blocks.filter(function(item){
                    return item == true;
                })
                return counter.length;
            }
            return sectionItems;
        },
        showHomeBlock: function(homeBlock){
            switch(homeBlock) {
                //International Students Home Block
                case "intlStudentHomeBlock":
                    return this.intlStudent;
                //Exchange Visitors Home Block
                case "exchangeVisitorsHomeBlock":
                    return this.j1Visa;
                //Travel Outside USA Home Block
                case "travelOutsideUSAHomeBlock":
                    return this.visitorOutsideUSA;
                //Schengen Visa Home Block
                case "schengenVisaHomeBlock":
                    return this.schengenVisa;
                //Trip Insurance Home Block
                case "tripInsuranceHomeBlock":
                    return this.tripInsurance;
                //New Immigrants Home Block
                case "newImmigrantHomeBlock":
                    return this.newImmigrant;
                //Visiting with Greeencard Home Block
                case "visitingWithGreencardHomeBlock":
                    return this.visitorGC;
                //Fiance Visa Home Block
                case "fianceVisaHomeBlock":
                    return this.imgTravel;
                //Temporary Workers Home Block
                case "temporaryWorkersHomeBlock":
                    return this.individualMedical;
                //Study Abroad Home Block
                case "studyAbroadHomeBlock":
                    return this.studyAbroad;
                //OPT Health Insurance Home Block
                case "optHealthInsHomeBlock":
                    return this.optHealthIns;
                //Evacuation & Repatriation Home Block
                case "evacRepatriationHomeBlock":
                    return this.evacRepatriation;
                //Flight Accident Home Block
                case "flightAccidentHomeBlock":
                    return this.flightAccident;
                //Annual MultiTrip Home Block
                case "annualMultiTripHomeBlock":
                    return this.annualMultitrip;
                //AD&D Home Block
                case "adanddHomeBlock":
                    return this.adandd;
                //Group Travel Home Block
                case "groupTravelHomeBlock":
                    return this.groupTravel;
                //Missionary Travel Home Block
                case "missionaryTravelHomeBlock":
                    return this.missionaryTravel;
                //Kidnap & Ransom Home Block
                case "kidnapRansomHomeBlock":
                    return this.kidnapRansom;
                //Expatriate Home Block
                case "expatriateHomeBlock":
                    return this.expatriates;
                //Marine Crew Home Block
                case "marineCrewHomeBlock":
                    return this.marine;
                //Career Missionaries Home Block
                case "careerMissionariesHomeBlock":
                    return this.missionaryLongTerm;
                //Short Term Medical Home Block
                case "shortTermMedicalHomeBlock":
                    return this.shortTermMedical;
                //Long Term Medical Home Block
                case "longTermMedicalHomeBlock":
                    return this.longTermMedical;
                //Life Home Block
                case "lifeHomeBlock":
                    return this.life;
                //Disability Home Block
                case "disabilityHomeBlock":
                    return this.disability;
                //Group Medical Home Block
                case "groupMedicalHomeBlock":
                    return this.groupMedical;
                //Dental Home Block
                case "dentalHomeBlock":
                    return this.dental;
            }
        },
        getBlockModifierClass: function(itemCount, masterClass) {
            return masterClass + numberString[itemCount - 1];
        },
        setSession: function() {
            // setItemSessionStorage('quotePlan', this.currentQuoteSelect);            
            if(this.currentQuoteSelect != 'covid19') {
                this.hasTripCal = false;
            }

            if(!this.hasTripCal) {
                setItemSessionStorage('hasTripCal', 'no');
            } else {
                setItemSessionStorage('hasTripCal', 'yes');
            }
        },
        showGeoModal: function (e, url) {
            var url = url || null;
            if (url != null && url.indexOf('$PLANID') > -1 && e.target.dataset) {
                url = url.replace('$PLANID', e.target.dataset.planId);
                url = url.replace('$PLANTYPE', e.target.dataset.planType);
                url = url.replace('$CURRENTLANG', e.target.dataset.currentLang);
            }
            clearTimeout(this.sessionTime);
            this.sessionTime = null;
            this.showModal(url);
        },
        setHeroUrl (isClick, quoteSelected) {
            if (!this.disableSelection) {
                this.currentQuoteSelect = (this.currentQuoteSelect == 'covid19' || this.hasTripCal) && !quoteSelected ? 'covid19' : !quoteSelected && this.currentQuoteSelect ? this.currentQuoteSelect : quoteSelected;

                if (isClick==false && !ibJS.tripInsurance) isClick = true;
                if (this.currentQuoteSelect) {
                    switch (this.currentQuoteSelect) {
                        case 'visitorUSA':
                            this.heroUrl = window.ibJS.heroUrl?.travelToUsa;
                            break;
                        case 'covid19':
                            this.heroUrl = this.hasTripCal ? window.ibJS.heroUrl?.tripIns : window.ibJS.heroUrl?.travelOutsideUsa;                        
                            break;
                        case 'domesticUSA':
                            this.heroUrl = window.ibJS.heroUrl?.domestic;
                            break;
                    }
                    if (isClick) {
                        this.setSession();
                        this.disableSelection = true;
                        setTimeout(() => {
                            this.disableSelection = false;
                        }, 1000);
                        window.location.href = this.heroUrl;
                    }
                    else if (isClick === false) {
                        this.sessionTime = setTimeout(() => {
                            if (this.sessionTime) {
                                this.disableSelection = true;
                                setTimeout(() => {
                                    this.disableSelection = false;
                                }, 1000);
                                this.setSession();
                                if(this.hasTripCal && window.ibJS.heroUrl?.tripIns) this.heroUrl = window.ibJS.heroUrl.tripIns;
                                window.location.href = this.heroUrl;
                            }
                        }, 7000);
                    }
                } else {
                    if (isClick) this.isFormSubmitted = true;
                }
            }
        },
        hasAffiliateVal(quoteSelected) {
            switch (quoteSelected) {
                case 'visitorUSA':
                    return this.visitorUSA;
                case 'covid19':
                    return this.visitorOutsideUSA;    
                case 'domesticUSA':
                    return this.tripInsurance;
            }           
        }
    }
});
